<template>
  <div class=" justify-content-center mx-auto">
    <Splide :options="{
                        pagination: false,
                        arrows : !$isMobile(),
                        perPage: ($isMobile() ? 1 :3),
                        gap: (!$isMobile() ? '2em' :'0em'), }">
      <SplideSlide class="news-card  card bg-dark text-left pb-4 " v-bind:class="{'news-card-mobile':$isMobile()}" v-for="(n, index) in news"  v-bind:key="index" >
              <template v-if="layout === 'read_more'">
                 <router-link class="card-img-top-anchor" :to="{name: 'news', params: {slug: n.slug}}">
                   <img class="w-100" :src="n.main_image" :alt="n.title">
                   <p class="font-weight-bold pt-3 pb-1 text-white news-title">{{n.title}}</p>
                 </router-link>


                 <div class="card-body p-0">
                   <p class="card-text short-description small text-muted" v-html="n.short_description"></p>

                   <router-link class="text-muted" :to="{name: 'news', params: {slug: n.slug}}">
                    {{ $t("Leer Más") }} 
                   </router-link>
                 </div>
               </template>
                <template v-else>
                  <router-link class="card-img-top-anchor" :to="{name: 'news', params: {slug: n.slug}}">
                    <div
                        class="card-img-top no-read-more"
                        v-bind:key="index+'bg'"
                        v-bind:style="newsBackgroundGenerator(n)"
                    >
                    </div>
                  </router-link>


                  <div class="card-body px-0">
                    <p class="font-weight-bold text-white card-text news-title">{{ n.title }}</p>
                  </div>
                </template>

      </SplideSlide>
    </Splide>
  </div>
<!--  <div class="row justify-content-center mx-auto">-->
<!--    <div v-for="(n, index) in newsFiltered" v-bind:key="index" class="news-card card bg-dark text-center col ">-->
<!--     <template v-if="layout === 'read_more'">-->
<!--       <router-link class="card-img-top-anchor" :to="{name: 'news', params: {slug: n.slug}}">-->
<!--         <div-->
<!--             class="card-img-top "-->
<!--             v-bind:key="index+'bg'"-->
<!--             v-bind:style="newsBackgroundGenerator(n)"-->
<!--         >-->
<!--           <p class="news-title">{{n.title}}</p>-->

<!--         </div>-->
<!--       </router-link>-->


<!--       <div class="card-body px-0">-->
<!--         <p class="card-text">{{ n.short_description }}</p>-->

<!--         <router-link class="btn btn-sm btn-primary mt-3" :to="{name: 'news', params: {slug: n.slug}}">-->
<!--           Leer Más-->
<!--         </router-link>-->
<!--       </div>-->
<!--     </template>-->
<!--      <template v-else>-->
<!--        <router-link class="card-img-top-anchor" :to="{name: 'news', params: {slug: n.slug}}">-->
<!--          <div-->
<!--              class="card-img-top no-read-more"-->
<!--              v-bind:key="index+'bg'"-->
<!--              v-bind:style="newsBackgroundGenerator(n)"-->
<!--          >-->
<!--          </div>-->
<!--        </router-link>-->


<!--        <div class="card-body px-0">-->
<!--          <p class="card-text">{{ n.title }}</p>-->
<!--        </div>-->
<!--      </template>-->

<!--    </div>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "HomeNews",
  props: {
    news: {
      required: true,
      type: Array
    },
    layout:{
      type: String,
      default: function() {
        return "read_more"//"no_read_more"
      }
    }
  },
  computed:{
    newsFiltered: function(){
      let start = this.position;
      let end = (this.position + this.items);
      let max = this.news.length;
      let limit = (max - this.items);
      end = (end > max ? max : end);
      start = (start > limit ? limit : start);
      return this.news.slice(start, end)
    }
  },
  data(){
    return {
      items: 4,
      position: 0
    }
  },
  methods:{
    newsBackgroundGenerator: function(news){
      return {
        background: 'url('+news.main_image+') no-repeat center center',
        backgroundSize: 'cover'
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.news-card {

  &.news-card-mobile{
    width: 80vw !important;
    .card-img-top {
      width: 80vw !important;;
      height: 60vw !important;;
    }
    .news-title{
      // height: 20vw;
      // bottom: 0;

    }
  }
  //background-color: $dark-blue;
  //font-size: 0.65rem;
  max-width: 440px !important;
  .card-img-top-anchor{
    color: $white;

    &:hover{
      text-decoration: none;
    }
    .card-img-top {
      width: 19vw;
      height: 13vw;
      border-color: $light-blue;
      border-style: solid;
      background: #020916;
      position: relative;
    }
  }


  .short-description{
    height: 4em;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .news-title{
    height: 4em;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // background: rgba(2, 9, 22, 0.7);
    // height: 5vw;
    // position: absolute;
    // bottom: 0;
    // padding: 1em;
    // text-align: justify;
    // margin-bottom: 0;
    // overflow: hidden;
  }
}

</style>