<template>
  <div class="container news-detail " v-if="currentNews" v-bind:key="currentNews.slug">

    <div class="row " >
      <div class="col">
        <h3 class="title text-center" v-html="currentNews.title" />

        <div class="mx-5 d-block" >
          <span class="small">Por: {{currentNews.author}}</span>
          <span class="small" v-if="currentNews.publish_at">{{currentNews.publish_at}}</span>
          <img class="w-100 rounded" :src="currentNews.main_image" alt="">

        </div>

      </div>
    </div>
    <div class="row mt-5" >
      <div class="col description" v-html="currentNews.description">

      </div>
    </div>
    <app-section title="Más contenido de tu interés"></app-section>
    <home-news v-if="newsList && newsList.length > 0" :news="newsList" layout="no_read_more" ></home-news>


  </div>
</template>

<script>
import {mapGetters} from "vuex";

import AppSection from "@/components/AppSection";
import HomeNews from "../components/home/HomeNews";

export default {
  name: "NewsPage",
  props: {
    slug : {
      required : false,
      type : undefined
    }
  },
  components:{
    HomeNews,AppSection
  },
  computed: {
    ...mapGetters(["news", "locale"]),
    newsList: function(){
      return this.news ? this.news.filter((n)=> n.slug !== this.slug) : null
    }
  },
  methods:{
    fetchNewsSlug: function(){
      this.$store.dispatch("fetchNewsSlug",this.slug).then(data => {
        this.currentNews = data;
      });
      this.$store.dispatch("fetchNews")
    }
  },
  data(){
    return{
      currentNews: null
    }
  },
  mounted() {
    this.fetchNewsSlug();
  },

}
</script>

<style lang="scss">
.news-detail{
  .description{
    p{
      margin-bottom: 0;
    }
    margin-bottom: 2em;
  }
}
</style>